// axios配置  可自行根据项目进行更改，只需更改该文件即可，其他文件可以不动
// The axios configuration can be changed according to the project, just change the file, other files can be left unchanged

import type { AxiosResponse } from 'axios'
import { clone } from 'lodash-es'
import type { RequestOptions, Result } from 'framework/axios'
import type { AxiosTransform, CreateAxiosOptions } from './axiosTransform'
import { VAxios } from './Axios'
import { useGlobSetting } from '@admin/hooks/setting'
import { useMessage } from 'framework/hooks/web/useMessage'
import { RequestEnum, ResultEnum, ContentTypeEnum } from 'framework/enums/httpEnum'
import { isString } from 'framework/utils/is'
import { getToken } from 'framework/utils/auth'
import { setObjToUrlParams, deepMerge } from 'framework/utils'
import { downLoadFile } from 'framework/utils/download'
import { joinTimestamp, formatRequestDate } from './helper'
import { useUserStoreWithOut } from '@admin/store/modules/user'
import { AxiosRetry } from '@admin/utils/http/axios/axiosRetry'
import { getQueryVariable } from 'framework/utils'
import { cloneDeep } from 'lodash-es'
import { RequestModeEnum } from 'framework/enums/appEnum'
import projectSetting from '@admin/settings/projectSetting'
// import { ExceptionEnum } from 'framework/enums/exceptionEnum'
import { envConfig, REQUEST_TYPE } from './config'
// import { globalConfig } from 'ant-design-vue/lib/config-provider'
import { ExceptionEnum } from 'framework/enums/exceptionEnum'
import { ENV, getEnv } from '@admin/utils/http/axios/config'

import { HEALTH_URL, LOCALE_TEXT } from 'framework/enums/cacheEnum'

const globSetting = useGlobSetting()
const urlPrefix = globSetting.urlPrefix
const { createMessage, createErrorModal } = useMessage()

/**
 * @description: 数据处理，方便区分多种处理方式
 */
const transform: AxiosTransform = {
  /**
   * @description: 处理响应数据。如果数据不是预期格式，可直接抛出错误
   */
  transformResponseHook: (res: AxiosResponse<Result>, options: RequestOptions) => {
    const requestMode = projectSetting.requestMode
    const responseType = res.config.responseType
    /** 拦截下载 */
    if (responseType && responseType === 'blob') {
      // 获取响应头
      const contentDisposition = res.headers['content-disposition']
      const documentType = res.config.documentType
      // 如果存在Content-Disposition头
      if (contentDisposition) {
        // 提取文件名
        const fileNameMatch = /filename="(.+)"/.exec(contentDisposition)
        if (fileNameMatch && fileNameMatch[1]) {
          const download_name = fileNameMatch[1]
          downLoadFile(res.data, decodeURIComponent(download_name), documentType)
        }
      } else {
        const { download_name } = res.config || JSON.parse(res.config.data || '{}')
        downLoadFile(res.data, download_name || Date.now() + '', documentType)
      }

      return res
    }
    if (
      requestMode == RequestModeEnum.MOCK &&
      (window as any).location.href.indexOf('new-admin-prod') == -1
    ) {
      const tempData = cloneDeep(res.data)
      delete res.data
      res.data = {}
      res.data.data = tempData
      res.data.code = 200
      res.data.msg = 'success'
      return res.data
    }
    const { isTransformResponse, isReturnNativeResponse } = options
    // 是否返回原生响应头 比如：需要获取响应头时使用该属性
    if (isReturnNativeResponse) {
      return res
    }
    // 不进行任何处理，直接返回
    // 用于页面代码可能需要直接获取code，data，message这些信息时开启
    if (!isTransformResponse) {
      return res.data
    }
    // 错误的时候返回

    if (!res.data) {
      // return '[HTTP] Request has no return value';
      throw new Error('请求异常，请重试')
    }

    //  这里 code，result，message为 后台统一的字段，需要在 types.ts内修改为项目自己的接口返回格式
    const { code, msg } = res.data
    // 这里逻辑可以根据项目进行修改

    const hasSuccess = Reflect.has(res.data, 'code') && code === ResultEnum.SUCCESS //去掉data的判断，存在保存接口code==200但是data返回null的情况
    // const hasSuccess = data && Reflect.has(res.data, 'code') && code === ResultEnum.SUCCESS

    if (hasSuccess) {
      return res.data
    }

    // 处理业务中的状态码
    let timeoutMsg = msg

    if (code === ResultEnum.TOKEN_ERROR) timeoutMsg = '登录过期，请重新登录'

    if (options.errorMessageMode === 'modal') {
      createErrorModal({ title: '错误提示', content: timeoutMsg })
    } else if (options.errorMessageMode === 'message') {
      createMessage.error(timeoutMsg)
    }

    if (code === ResultEnum.TOKEN_ERROR) {
      setTimeout(() => {
        const userStore = useUserStoreWithOut()
        userStore.resetState()
        location.reload()
      }, 1000)
    }

    throw new Error(timeoutMsg || 'Error')
  },

  // 请求之前处理config
  beforeRequestHook: (config, options) => {
    const {
      apiUrl,
      pmsUrl,
      imsUrl,
      wmsUrl,
      omsUrl,
      landongUrl,
      joinParamsToUrl,
      formatDate,
      joinTime = true,
      // urlPrefix,
      baseApiUrl,
    } = options
    const { requestApiType = globSetting.apiUrl } = config

    const apiUrlList = [apiUrl, pmsUrl, imsUrl, wmsUrl, omsUrl, landongUrl].filter(Boolean)

    const findIndex = apiUrlList.findIndex((item) => item == requestApiType)

    let suffixUrl = '-eo.bloomeverybody.work'
    const healthUrl = localStorage.getItem(HEALTH_URL)
    if (config?.healthUrl || healthUrl) {
      suffixUrl = config.healthUrl?.healthUrl || healthUrl
    }

    if (baseApiUrl) {
      config.url = `${envConfig[baseApiUrl]}${getEnv() == ENV.LOCAL ? '' : suffixUrl}${config.url}`
    } else if (apiUrlList.length && apiUrlList[findIndex] && isString(apiUrlList[findIndex])) {
      config.url = `${apiUrlList[findIndex]}${getEnv() == ENV.LOCAL ? '' : suffixUrl}${config.url}`
    }

    const params = config.params || {}
    const data = config.data || false
    formatDate && data && !isString(data) && formatRequestDate(data)
    if (config.method?.toUpperCase() === RequestEnum.GET) {
      if (!isString(params)) {
        // 给 get 请求加上时间戳参数，避免从缓存中拿数据。
        config.params = Object.assign(params || {}, joinTimestamp(joinTime, false))
      } else {
        // 兼容restful风格
        config.url = config.url + params + `${joinTimestamp(joinTime, true)}`
        config.params = undefined
      }
    } else {
      if (!isString(params)) {
        formatDate && formatRequestDate(params)
        if (
          Reflect.has(config, 'data') &&
          config.data &&
          (Object.keys(config.data).length > 0 || config.data instanceof FormData)
        ) {
          config.data = data
          config.params = params
        } else {
          // 非GET请求如果没有提供data，则将params视为data
          config.data = params
          config.params = undefined
        }
        if (joinParamsToUrl) {
          config.url = setObjToUrlParams(
            config.url as string,
            Object.assign({}, config.params, config.data),
          )
        }
      } else {
        // 兼容restful风格
        config.url = config.url + params
        config.params = undefined
      }
    }
    if (getQueryVariable('test')) {
      if (config.method?.toUpperCase() === RequestEnum.GET) {
        if (config.params == 'null' || config.params == null || config.params == undefined)
          config.params = {}
        Object.assign(config.params, { sy_debug: 'sy20222023' })
      } else {
        if (config.data == 'null' || config.data == null || config.params == undefined)
          config.data = {}
        Object.assign(config.data, { sy_debug: 'sy20222023' })
      }
    }
    const requestMode = projectSetting.requestMode

    if (
      config.mockUrl &&
      requestMode == RequestModeEnum.MOCK &&
      (window as any).location.href.indexOf('new-admin-prod') == -1
    ) {
      config.url = config.mockUrl
    }
    return config
  },

  /**
   * @description: 请求拦截器处理
   */
  requestInterceptors: (config, options) => {
    // 请求之前处理config
    const token = getToken()
    if (token && (config as Recordable)?.requestOptions?.withToken !== false) {
      ;(config as Recordable).headers['X-Token'] = options.authenticationScheme
        ? `${options.authenticationScheme} ${token}`
        : token
      ;(config as Recordable).headers['token-type'] = 'feishu'
      const locale = localStorage.getItem(LOCALE_TEXT)

      ;(config as Recordable).headers['Language'] = locale == 'zh_CN' ? 'zh' : locale
    }
    return config
  },

  /**
   * @description: 响应拦截器处理
   */
  responseInterceptors: (res: AxiosResponse<any>) => {
    return res
  },

  /**
   * @description: 作用于http状态码
   */
  responseInterceptorsCatch: (axiosInstance: AxiosResponse, error: any) => {
    const { response = {}, code, message, config } = error || {}
    const errorMessageMode = config?.requestOptions?.errorMessageMode || 'none'
    const err: string = error?.toString?.() ?? ''
    let errMessage = ''
    const statusCode = response.status
    try {
      console.log(error, 'code')
      if (code === 'ECONNABORTED' && message.indexOf('timeout') !== -1) {
        errMessage = '接口请求超时，请刷新重试'
      }
      if (err?.includes('Network Error')) {
        errMessage = '网络异常，请检查您的网络是否正常！'
      }
      if (statusCode === ExceptionEnum.PAGE_NOT_ACCESS) {
        errMessage = '无权限，请联系系统管理员！'
      }
      if ([ExceptionEnum.CODE_ERROR, ExceptionEnum.ERROR].includes(statusCode)) {
        errMessage = response?.data?.msg || '请求异常'
      }
      if (errMessage) {
        if (errorMessageMode === 'modal') {
          createErrorModal({ title: '错误提示', content: errMessage })
        } else if (errorMessageMode === 'message') {
          createMessage.error(errMessage)
        }
        return Promise.reject(error)
      }
    } catch (error) {
      throw new Error(error as unknown as string)
    }

    // 添加自动重试机制 保险起见 只针对GET请求
    const retryRequest = new AxiosRetry()
    const { isOpenRetry } = config.requestOptions.retryRequest
    config.method?.toUpperCase() === RequestEnum.GET &&
      isOpenRetry &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      retryRequest.retry(axiosInstance, error)
    return Promise.reject(error)
  },
}

function createAxios(opt?: Partial<CreateAxiosOptions>) {
  return new VAxios(
    // 深度合并
    deepMerge(
      {
        // authentication schemes，e.g: Bearer
        // authenticationScheme: 'Bearer',
        authenticationScheme: '',
        timeout: 0,
        // 基础接口地址
        // baseURL: globSetting.apiUrl,

        headers: { 'Content-Type': ContentTypeEnum.JSON },
        // 如果是form-data格式
        // headers: { 'Content-Type': ContentTypeEnum.FORM_URLENCODED },
        // 数据处理方式
        transform: clone(transform),
        // 配置项，下面的选项都可以在独立的接口请求中覆盖
        requestOptions: {
          // 默认将prefix 添加到url
          joinPrefix: true,
          // 是否返回原生响应头 比如：需要获取响应头时使用该属性
          isReturnNativeResponse: false,
          // 需要对返回数据进行处理
          isTransformResponse: true,
          // post请求的时候添加参数到url
          joinParamsToUrl: false,
          // 格式化提交参数时间
          formatDate: true,
          // 消息提示类型
          errorMessageMode: 'message',
          baseApiUrl: '',
          // 接口地址
          apiUrl: globSetting.apiUrl,
          // 接口地址
          pmsUrl: globSetting.pmsApiUrl,
          imsUrl: globSetting.imsApiUrl,
          wmsUrl: globSetting.wmsApiUrl,
          omsUrl: globSetting.omsApiUrl,
          landongUrl: globSetting.landongApiUrl,
          // 接口拼接地址
          urlPrefix: urlPrefix,
          //  是否加入时间戳
          joinTime: true,
          // 忽略重复请求
          ignoreCancelToken: true,
          // 是否携带token
          withToken: true,
          retryRequest: {
            isOpenRetry: false,
            count: 5,
            waitTime: 100,
          },
        },
      },
      opt || {},
    ),
  )
}

export const defHttp = createAxios()

export const adminRequest = createAxios({ requestOptions: { baseApiUrl: REQUEST_TYPE.ADMIN } })

export const pmsRequest = createAxios({ requestOptions: { baseApiUrl: REQUEST_TYPE.PMS } })

export const wmsRequest = createAxios({ requestOptions: { baseApiUrl: REQUEST_TYPE.WMS } })

export const twoiWmsRequest = createAxios({ requestOptions: { baseApiUrl: REQUEST_TYPE.TWOI_WMS } })

export const plmRequest = createAxios({ requestOptions: { baseApiUrl: REQUEST_TYPE.PLM } })

export const imsRequest = createAxios({ requestOptions: { baseApiUrl: REQUEST_TYPE.IMS } })

export const omsRequest = createAxios({ requestOptions: { baseApiUrl: REQUEST_TYPE.OMS } })

export const tmsRequest = createAxios({ requestOptions: { baseApiUrl: REQUEST_TYPE.TMS } })

export const commonRequest = createAxios({ requestOptions: { baseApiUrl: REQUEST_TYPE.COMMON } })

export const cdbRequest = createAxios({ requestOptions: { baseApiUrl: REQUEST_TYPE.CDB } })

export const copsRequest = createAxios({ requestOptions: { baseApiUrl: REQUEST_TYPE.COPS } })

export const landongRequest = createAxios({ requestOptions: { baseApiUrl: REQUEST_TYPE.LANDONG } })

export const kolRequest = createAxios({ requestOptions: { baseApiUrl: REQUEST_TYPE.KOL } })
