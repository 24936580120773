import { LAYOUT } from '@admin/router/constant'

// 物料管理
const materialManagementRouter = {
  path: '/materialManagement',
  name: '',
  component: LAYOUT,
  children: [
    {
      path: '/material-management/material-supplier',
      name: 'materialSupplier',
      component: () => import('@admin/views/plm/materialManagement/materialSupplier/index.vue'),
      meta: { helpUrl: '', title: '面辅料供应商' },
    },
    {
      path: '/material-management/material-supplier-detail',
      name: 'materialSupplierDetail',
      component: () => import('@admin/views/plm/materialManagement/materialSupplier/detail.vue'),
      meta: { helpUrl: '', title: '面辅料供应商详情' },
    },
    {
      path: '/material-management/material-attributes',
      name: 'materialAttributes',
      component: () => import('@admin/views/plm/materialManagement/materialAttributes/index.vue'),
      meta: { helpUrl: '', title: '物料属性' },
    },
    {
      path: '/material-management/material-inspection-project',
      name: 'materialInspectionProject',
      component: () =>
        import('@admin/views/plm/materialManagement/materialInspectionProject/index.vue'),
      meta: { helpUrl: '', title: '物料检测项目' },
    },
    // {
    //   path: '/material-management/material-classification',
    //   name: 'materialClassification',
    //   component: () =>
    //     import('@admin/views/plm/materialManagement/materialClassification/index.vue'),
    //   meta: { helpUrl: '', title: '物料分类' },
    // },
    {
      path: '/material-management/material-file',
      name: 'materialFile',
      component: () => import('@admin/views/plm/materialManagement/materialFile/index.vue'),
      meta: { helpUrl: '', title: '档案管理' },
    },
    {
      path: '/material-management/sa-fabric',
      name: 'saFabric',
      component: () => import('@admin/views/plm/materialManagement/SAFabric/index.vue'),
      meta: { helpUrl: '', title: 'SA面料库' },
    },
  ],
}

export default materialManagementRouter
